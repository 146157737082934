import React, { useState } from 'react';
import * as styles from '../pages/shop.module.css';
import { graphql, navigate } from 'gatsby';
import Layout from '../components/Layout/Layout';
import { Container, ProductPageTemplate, Spacer } from '../uiBook';
import Breadcrumbs from '../components/Breadcrumbs';
import { useAppContext } from '../context/useAppContext';

export default function ProductPage({ data }) {
  const [pageData] = useState(data?.googleProductsSheet);
  const { basket, setBasket } = useAppContext();

  const handleAddToBasket = () => {
    setBasket([...basket, pageData]);
  };

  const handleOnCheckout = () => {
    navigate('/cart');
  };

  const handleContinueShopping = () => {
    navigate('/shop');
  };

  return (
    <Layout disablePaddingBottom>
      <Container center>
        <Spacer height={60} />
        <Container maxWidth="1400px">
          <div className={styles.breadcrumbContainer}>
            <Breadcrumbs
              crumbs={[
                { link: '/', label: 'Home' },
                { link: '/shop', label: 'Items' },
                {
                  link: null,
                  label: pageData?.title,
                },
              ]}
            />
          </div>
        </Container>
        <Container maxWidth="1400px" padding="16px">
          <ProductPageTemplate
            title={pageData?.title}
            copy={pageData?.copy}
            price={pageData?.price}
            images={pageData?.images?.split(',')}
            condition={pageData?.condition}
            dimensions={pageData?.dimensions}
            provenance={pageData?.provenance}
            date={pageData?.date}
            onAddToBasket={handleAddToBasket}
            onCheckout={handleOnCheckout}
            onContinueShopping={handleContinueShopping}
          />
        </Container>
        <Spacer height={32} />
      </Container>
    </Layout>
  );
}
export const query = graphql`
  query ($slug: String!) {
    googleProductsSheet(slug: { eq: $slug }) {
      slug
      title
      provenance
      price
      date
      copy
      condition
      category
      dimensions
      images
      sku
      weight
    }
  }
`;
